<template>
  <div class="mx-8">
    <div class="d-flex justify-center text-h5">
      RKBMN TAHUN {{ detailRKBMN.tahunkebutuhan }}
    </div>
    <div v-if="detailRKBMN.satker" class="d-flex justify-center text-h6">
      {{ detailRKBMN.satker.label }}
    </div>

    <div class="d-flex justify-center mb-4">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
            color="error"
            class="mr-1"
            @click="showDialogEdit()"
            >mdi-pencil-box</v-icon
          >
        </template>
        <span>Edit Data</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
            color="error"
            class="mr-1"
            @click="dialogDelete = true"
            >mdi-delete</v-icon
          >
        </template>
        <span>Hapus Data</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
            color="error"
            @click="dialogLampiran = true"
            >mdi-file-plus</v-icon
          >
        </template>
        <span>Tambah Dokumen</span>
      </v-tooltip>
    </div>

    <v-divider></v-divider>

    <v-data-table :headers="headers" :items="detailRKBMN.lampiran">
      <template v-slot:item.nomor="{ item }">
        <span>{{ detailRKBMN.lampiran.indexOf(item) + 1 }}</span>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              color="error"
              class="mr-2"
              @click="openDocument(item)"
              >mdi-eye</v-icon
            >
          </template>
          <span>Lihat</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              color="error"
              class="mr-2"
              @click="showDialogEditDocument(item)"
              >mdi-pencil-box</v-icon
            >
          </template>
          <span>Edit</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              color="error"
              @click="showDialogDeleteDocument(item)"
              >mdi-delete</v-icon
            >
          </template>
          <span>Hapus</span>
        </v-tooltip>
      </template>

      <template v-slot:no-data>
        <label>Tidak ada data</label>
      </template>
    </v-data-table>

    <v-dialog v-model="dialogLampiran" width="400">
      <v-card>
        <v-card-title class="text-h5 error white--text">
          Tambah Dokumen RKBMN
        </v-card-title>

        <br />

        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-file-input
              label="File"
              v-model="dataLampiran.file"
              accept=".pdf"
              hide-details
              :rules="requiredRules"
              required
            ></v-file-input>
            <span class="d-flex justify-end caption"
              >Ukuran File Maksimal 30MB</span
            >

            <v-text-field
              label="Info Dokumen"
              v-model="dataLampiran.info"
              :rules="requiredRules"
              required
            ></v-text-field>

            <v-textarea
              label="Keterangan"
              v-model="dataLampiran.keterangan"
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogLampiran = false">
            Batal
          </v-btn>
          <v-btn color="primary" text @click="addLampiran()"> Simpan </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEdit" width="400">
      <v-card>
        <v-card-title class="text-h5 error white--text">
          Edit Data
        </v-card-title>

        <br />

        <v-card-text>
          <v-form>
            <v-select
              label="Tahun"
              v-model="dataEdit.tahunkebutuhan"
              :items="tahunOptions"
            ></v-select>
            <v-textarea
              label="Keterangan"
              v-model="dataEdit.keterangan"
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogEdit = false">
            Batal
          </v-btn>
          <v-btn color="primary" text @click="editData()"> Simpan </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" width="400">
      <v-card>
        <v-card-title class="text-h5 error white--text">
          Hapus Data
        </v-card-title>

        <br />

        <v-card-text
          >Apakah anda yakin menghapus seluruh data RKBMN Tahun
          <strong> {{ detailRKBMN.tahunkebutuhan }} </strong> ?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogDelete = false">
            Tidak
          </v-btn>
          <v-btn color="primary" text @click="deleteData()"> Ya </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEditLampiran" width="400">
      <v-card>
        <v-card-title class="text-h5 error white--text">
          Edit Dokumen RKBMN
        </v-card-title>

        <br />

        <v-card-text>
          <v-form>
            <v-file-input
              label="File"
              v-model="dataEditLampiran.file"
              accept=".pdf"
              hide-details
            ></v-file-input>
            <span class="d-flex justify-end caption"
              >Ukuran File Maksimal 30MB</span
            >

            <v-text-field
              label="Info Dokumen"
              v-model="dataEditLampiran.info"
            ></v-text-field>

            <v-textarea
              label="Keterangan"
              v-model="dataEditLampiran.keterangan"
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogEditLampiran = false">
            Batal
          </v-btn>
          <v-btn color="primary" text @click="editLampiran()"> Simpan </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDeleteLampiran" width="400">
      <v-card>
        <v-card-title class="text-h5 error white--text">
          Hapus Data Lampiran
        </v-card-title>

        <br />

        <v-card-text
          >Apakah anda yakin menghapus
          <strong> {{ dataDeleteLampiran.info }} </strong> ?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogDeleteLampiran = false">
            Tidak
          </v-btn>
          <v-btn color="primary" text @click="deleteLampiran()"> Ya </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" color="success" timeout="1000">
      {{ snackbarText }}

      <template v-slot:action>
        <v-btn fab x-small color="white" @click="snackbar = false">
          <v-icon color="error">mdi-close-thick</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: this.$route.params.id,
      headers: [
        { text: "No.", value: "nomor", width: "70px", sortable: false },
        { text: "Dokumen RKBMN", value: "info" },
        { text: "Keterangan", value: "keterangan", sortable: false },
        { text: "Aksi", value: "actions", sortable: false },
      ],
      dialogLampiran: false,
      dataLampiran: {
        info: "",
        file: [],
        keterangan: "",
      },
      dialogEditLampiran: false,
      dataEditLampiran: {
        id: "",
        info: "",
        file: [],
        keterangan: "",
      },
      dialogEdit: false,
      dataEdit: {
        tahunkebutuhan: "",
        keterangan: "",
      },
      tahunOptions: [],
      dialogDelete: false,
      dialogDeleteLampiran: false,
      dataDeleteLampiran: {
        id: "",
        info: "",
      },

      valid: true,
      requiredRules: [(v) => !!v || "Data harus diisi"],
      snackbar: false,
      snackbarText: "",
    };
  },
  watch: {
    dialogLampiran() {
      if (!this.dialogLampiran) {
        this.$refs.form.reset();
        this.dataLampiran.info = "";
        this.dataLampiran.file = [];
        this.dataLampiran.keterangan = "";
      }
    },
  },
  computed: {
    detailRKBMN() {
      return this.$store.getters.detailRKBMN;
    },
  },
  created() {
    this.init();
    for (let i = 2023; i <= 2100; i++) {
      this.tahunOptions.push(i.toString());
    }
  },
  methods: {
    init() {
      this.$store.dispatch("getDetailRKBMN", this.id);
    },
    addLampiran() {
      this.$refs.form.validate();
      if (this.dataLampiran.info != "" && this.dataLampiran.file != "") {
        if (this.valid) {
          const data = new FormData();
          data.set("id", this.id);
          data.set("info", this.dataLampiran.info);
          data.set("file", this.dataLampiran.file);
          data.set("keterangan", this.dataLampiran.keterangan);

          this.$store.dispatch("addFileRKBMN", data).then((value) => {
            this.snackbar = true;
            this.snackbarText = value.pesan;
            if (value.hasil == "success") {
              this.init();
              this.dialogLampiran = false;
            }
          });
        }
      } else {
        this.snackbar = true;
        this.snackbarText = "File dan Info Dokumen tidak boleh kosong";
      }
    },
    openDocument(item) {
      this.$store.dispatch("getLinkDocument", item.file).then((res) => {
        window.open(res.data, "_blank");
      });
    },
    showDialogEdit() {
      this.dataEdit.tahunkebutuhan = this.detailRKBMN.tahunkebutuhan.toString();
      this.dataEdit.keterangan = this.detailRKBMN.keterangan;
      this.dialogEdit = true;
    },
    editData() {
      const data = {
        id: this.id,
        tahun: (parseInt(this.dataEdit.tahunkebutuhan) - 2).toString(),
        tahunkebutuhan: this.dataEdit.tahunkebutuhan,
        keterangan: this.dataEdit.keterangan,
      };

      this.$store.dispatch("editRKBMN", data).then((value) => {
        this.snackbar = true;
        this.snackbarText = value.pesan;
        if (value.hasil == "success") {
          this.init();
          this.dialogEdit = false;
        }
      });
    },
    deleteData() {
      const data = {
        id: this.id,
      };
      this.$store.dispatch("deleteRKBMN", data).then((value) => {
        this.snackbar = true;
        this.snackbarText = value.pesan;
        this.dialogDelete = false;
        if (value.hasil == "success") {
          this.$router.go(-1);
        }
      });
    },
    showDialogEditDocument(item) {
      this.dataEditLampiran.id = item._id;
      this.dataEditLampiran.info = item.info;
      this.dataEditLampiran.keterangan = item.keterangan;
      this.dialogEditLampiran = true;
    },
    editLampiran() {
      const data = new FormData();
      data.set("id", this.dataEditLampiran.id);
      data.set("info", this.dataEditLampiran.info);
      data.set("file", this.dataEditLampiran.file);
      data.set("keterangan", this.dataEditLampiran.keterangan);

      this.$store.dispatch("editFileRKBMN", data).then((value) => {
        this.snackbar = true;
        this.snackbarText = value.pesan;
        if (value.hasil == "success") {
          this.init();
          this.dialogEditLampiran = false;
        }
      });
    },
    showDialogDeleteDocument(item) {
      this.dataDeleteLampiran.id = item._id;
      this.dataDeleteLampiran.info = item.info;
      this.dialogDeleteLampiran = true;
    },
    deleteLampiran() {
      const data = {
        id: this.dataDeleteLampiran.id,
      };
      this.$store.dispatch("deleteFileRKBMN", data).then((value) => {
        this.snackbar = true;
        this.snackbarText = value.pesan;
        if (value.hasil == "success") {
          this.init();
          this.dialogDeleteLampiran = false;
        }
      });
    },
  },
};
</script>